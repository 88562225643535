import { SELLER_CENTRAL_ROOT_PATH } from 'constants/amazon_seller_accounts'
import moment from 'moment'

import { randomString } from 'helpers/strings'
import { setLocalData } from 'helpers/storage'

import { SELLING_PARTNER_SYNC_CALLBACK_URL } from 'constants/routes'
import { LOCAL_STORAGE_KEYS } from 'constants/account'

export const extractSelectedCountryFromAccountSelector = (
  amazonSellerAccountSelector = {}
) => {
  const { selectedCountry = null } = amazonSellerAccountSelector

  return selectedCountry
}

export const getSelectedCountryInfo = country => ({
  marketplace_id: country?.marketplace_id,
  country_code: country?.country_code?.toLowerCase() || 'us'
})

/**
 * Returns the selected Amazon Seller Account info
 * @returns {{amazon_seller_account_id?: number, has_ppc_access?: boolean, marketplace_id?: number, country_code: string, label?: string, hasSellerAccount: boolean, selectedAccount?: any, selectedCountry?: any}}
 */
export const getSelectedAmazonSellerAccount = state => {
  const { selectedAccount, selectedCountry } =
    state?.amazonSellerAccounts?.amazonSellerAccountSelector || {}

  if (selectedAccount && selectedCountry) {
    return {
      hasSellerAccount: true,
      selectedAccount,
      selectedCountry,
      amazon_seller_account_id: selectedAccount.id,
      has_ppc_access: selectedAccount.has_ppc_access,
      label: selectedAccount.label,
      ...getSelectedCountryInfo(selectedCountry)
    }
  }

  return { hasSellerAccount: false, country_code: 'us', has_ppc_access: false }
}

/**
 * Checks for the existence of marketplaces and if a primary one is selected.
 *
 * If oldSelectedCountry object is passed, this function will check if the selected country
 * in the newSelectedCountry object is different from the one in the oldSelectedCountry parameter,
 * or if the marketplace_id have changed in similar way (this works for example when user changes marketplaces
 * from US of one account to US of another - this should trigger a was-updated flag).
 * If this is true, then the action gets fired. If not, request gets ignored.
 *
 * If oldSelectedCountry is not passed then existence of a new selected country is checked.
 * If there is one selected the action is fired. If not,the request is ignored.
 */
export const checkForMarketplaceUpdate = ({
  newSelectedCountry,
  oldSelectedCountry = null,
  action
}) => {
  if (!newSelectedCountry || !action) {
    return
  }

  // Called inside componentDidUpdate, will fire only if the marketplace was updated
  if (oldSelectedCountry) {
    const { country_code: newCountryCode, marketplace_id: newMrktId } =
      newSelectedCountry || {}
    const { country_code: oldCountryCode, marketplace_id: oldMrktId } =
      oldSelectedCountry || {}

    const marketplaceUpdated =
      newCountryCode &&
      (newCountryCode.toLowerCase() !== oldCountryCode.toLowerCase() ||
        newMrktId !== oldMrktId)

    if (marketplaceUpdated) {
      action()
      return true
    }
  } else if (newSelectedCountry && newSelectedCountry.country_code) {
    // To take into consideration the first mount where oldSelectedCountry is not yet defined
    // but we have a newSelectedCountry value
    action()
    return true
  }

  return false
}

/**
 * Checks if the marketplace call is still in progress
 */
export const isLoadingMarketplaces = props =>
  !!props?.amazonSellerAccounts?.amazonSellerAccountSelector?.isLoading

export const redirectToSellerCentralConsent = ({
  region,
  amazonMarketplaceId,
  label,
  email,
  redirectFromDisabledState
}) => {
  const regionLower = region.toLowerCase()
  const path = SELLER_CENTRAL_ROOT_PATH[regionLower][amazonMarketplaceId]

  const clientId = process.env.REACT_APP_SELLING_PARTNER_API_CLIENT_ID
  const redirectUri = window.location.origin + SELLING_PARTNER_SYNC_CALLBACK_URL
  const redirectUriParam = `&redirect_uri=${redirectUri}`

  const stateParam = randomString(20)

  const state = {
    stateParam,
    label,
    region,
    email,
    primaryMarketplaceId: amazonMarketplaceId,
    expires: moment(Date.now())
      .add(1, 'h')
      .toString(),
    originPath: window.location.hash.substr(1),
    redirectFromDisabledState
  }

  setLocalData(
    LOCAL_STORAGE_KEYS.MWS_SYNC_REDIRECT_STATE,
    JSON.stringify(state)
  )

  window.location.href = `${path}/apps/authorize/consent?application_id=${clientId}&state=${stateParam}${redirectUriParam}`
}

export const redirectToSellerCentralAuthForm = ({
  region,
  amazonMarketplaceId,
  redirectFromDisabledState
}) => {
  const regionLower = region.toLowerCase()
  const path = SELLER_CENTRAL_ROOT_PATH[regionLower][amazonMarketplaceId]

  const clientId = process.env.REACT_APP_SELLING_PARTNER_API_CLIENT_ID
  const redirectUri = window.location.origin + SELLING_PARTNER_SYNC_CALLBACK_URL
  const redirectUriParam = `&redirect_uri=${redirectUri}`

  const stateParam = randomString(20)

  const state = {
    stateParam,
    region,
    primaryMarketplaceId: amazonMarketplaceId,
    expires: moment(Date.now())
      .add(1, 'h')
      .toString(),
    originPath: window.location.hash.substr(1),
    redirectFromDisabledState
  }

  setLocalData(
    LOCAL_STORAGE_KEYS.MWS_SYNC_REDIRECT_STATE,
    JSON.stringify(state)
  )

  window.location.href = `${path}/apps/authorize/consent?application_id=${clientId}&state=${stateParam}${redirectUriParam}`
}
